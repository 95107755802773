@import '../values/colours.css';

:root {
  --border-spacing: 0.2rem;
}

.ability {
  composes: ability from '../shared/ability.css';
  background: url('../../images/tactic-background.svg') no-repeat 0 0;
  background-size: cover;
  cursor: pointer;
  padding: 0;
  margin: var(--border-spacing);
}
.abilityMasteryActive {
  composes: ability;
  background: url('../../images/optional-tactic-active.svg') no-repeat 0 0;
  background-size: cover;
}
.abilityMasterySelected {
  composes: ability;
  background: transparent;
  background-color: transparent;
}
.abilitySelected {
  composes: ability;
  margin: 0;
  padding: var(--border-spacing);
  background: url('../../images/tactic-background-selected.svg') no-repeat 0 0;
  background-size: cover;
}
.image {
  composes: image from '../shared/ability.css';
}
.imageInactive {
  composes: imageInactive from '../shared/ability.css';
}
