@import '../values/colours.css';

.overlay {
  display: none;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--color-transparent-black);
}
.overlayActive {
  composes: overlay;
  display: block;
}
.overlayActiveInvisible {
  composes: overlayActive;
  background: var(--color-transparent);
}
