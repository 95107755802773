.image {
  composes: image from '../shared/ability.css';
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3rem;
}

.ability {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary-1);
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.2rem;
  padding: 0.3rem;
  text-align: center;
  cursor: pointer;
  font-size: var(--font-size-1);
}

.popoverRow {
  composes: row from '../shared/popover.css';
}
.popoverRowLarge {
  composes: rowLarge from '../shared/popover.css';
}
.popoverItem {
  composes: item from '../shared/popover.css';
}
.popoverItemLarge {
  composes: itemLarge from '../shared/popover.css';
}
.popoverItemRight {
  composes: itemTitle from '../shared/popover.css';
}
.popoverItemTitle {
  composes: itemTitle from '../shared/popover.css';
}
.popoverItemTitleRight {
  composes: itemTitleRight from '../shared/popover.css';
}
.popoverNote {
  composes: note from '../shared/popover.css';
}
.popoverDescription {
  composes: description from '../shared/popover.css';
}
.popoverDivider {
  composes: divider from '../shared/popover.css';
}
.popoverImage {
  composes: image from '../shared/popover.css';
}
