@import '../values/widths.css';
@import '../values/colours.css';
@import '../values/fontSizes.css';

:root {
  --width-sidebar-border: 0.2rem;
}

.container {
  z-index: 10;
  transition: all 500ms ease;
  position: fixed;
  top: 0;
  /* Have to hard code this because can't use values with Calc just yet */
  left: -25rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--color-base-1);
  border-right: var(--width-sidebar-border) solid var(--color-base-5);
}
.containerActive {
  composes: container;
  transform: translateX(var(--width-sidebar));
}
.content {
  width: calc(var(--width-sidebar) - var(--width-sidebar-border));
}
.item {
  display: flex;
  height: 3rem;
  padding-left: 0.3rem;
  align-items: center;
  transition: background 0.1s ease;
  color: var(--color-text-secondary);

  text-decoration: none;
  font-size: var(--font-size-1);
  border-bottom: 1px solid var(--color-base-0);
  font-weight: 300;
}
.item:hover {
  background: var(--color-base-2);
  color: var(--color-text-secondary);
}
.icon {
  composes: careerIconSmallest from '../shared/careerIcon.css';
  position: relative;
  top: 0.5rem;
}
