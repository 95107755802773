@import '../values/spacing.css';
@import '../values/colours.css';

.careerIcon {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: var(--spacing-small);
}
@media screen and (width < 480px) {
  .careerIcon {
    width: 2.4rem;
    height: 2.4rem;
  }
}
.careerIconSmall {
  composes: careerIcon;
  width: 2.2rem;
  height: 2.2rem;
}
.careerIconSmaller {
  composes: careerIcon;
  width: 2rem;
  height: 2rem;
}
.careerIconSmallest {
  composes: careerIcon;
  width: 1.8rem;
  height: 1.8rem;
}
.careerIconBordered {
  composes: careerIcon;
  border: 2px solid var(--color-base-5);
  border-radius: 50%;
}
