@import '../values/spacing.css';
@import '../values/colours.css';

.box {
  padding-top: var(--spacing);
  padding-right: var(--spacing);
  padding-bottom: var(--spacing);
  padding-left: var(--spacing);
  background-color: var(--color-base-3);
}
