@import '../values/colours.css';

:root {
  --points-available: #8a4141;
}

.meter {
  display: flex;
  flex-direction: column-reverse;
  width: 5rem;
  background-color: var(--color-base-1);
  padding: 0.4rem;
  margin-bottom: 0.4rem;
  margin-top: 0.5rem;
}
.level {
  height: 1.86rem;
  line-height: 1.6rem;
  border: 1px solid var(--color-points-dark);
  text-align: center;
  background-color: var(--color-points-dark);
  margin-bottom: 0.2rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: var(--font-size-small);
}
.levelActive {
  composes: level;
  background-color: var(--color-points);
  border: 1px solid var(--color-points);
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.levelAvailable {
  composes: level;
  border: 1px solid var(--points-available);
  background-color: var(--points-available);
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
}
