@import '../values/colours.css';

.icon {
  width: 16px;
  height: 16px;
  fill: var(--color-text-primary);
}

.icon--small {
  width: 8px;
  height: 8px;
}
