.button {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  composes: buttonSecondary from '../shared/button.module.css';
  width: 48%;
  height: 2rem;
}
.buttonDisabled {
  composes: buttonDisabled from '../shared/button.module.css';
}
