@import '../values/spacing.css';

.marginRight {
  margin-right: var(--spacing);
}

.marginRight--extra-small {
  margin-right: var(--spacing-extra-small);
}

.marginRight--small {
  margin-right: var(--spacing-small);
}

@media screen and (width < 480px) {
  .marginRight--small\@mobile {
    margin-right: var(--spacing-small);
  }
}

.marginRight--medium {
  margin-right: var(--spacing-medium);
}

.marginRight--large {
  margin-right: var(--spacing-large);
}

@media screen and (width < 480px) {
  .marginRight--large\@mobile {
    margin-right: var(--spacing-large);
  }
}

.marginRight--extra-large {
  margin-right: var(--spacing-extra-large);
}

@media screen and (width < 480px) {
  .marginRight\@mobile {
    margin-right: var(--spacing);
  }
}

@media screen and (width >= 768px) {
  .marginRight\@sm-min {
    margin-right: var(--spacing);
  }
}

@media screen and (width >= 992px) {
  .marginRight\@md-min {
    margin-right: var(--spacing);
  }
}

.marginRight--none {
  margin-right: 0;
}

@media screen and (width < 480px) {
  .marginRight--none\@mobile {
    margin-right: 0;
  }
}

@media screen and (width < 768px) {
  .marginRight--none\@xs {
    margin-right: 0;
  }
}

@media screen and (768px <= width < 992px) {
  .marginRight--none\@sm {
    margin-right: 0;
  }
}

@media screen and (width >= 768px) {
  .marginRight--none\@sm-min {
    margin-right: 0;
  }
}

@media screen and (992px <= width < 1200px) {
  .marginRight--none\@md {
    margin-right: 0;
  }
}

@media screen and (width >= 992px) {
  .marginRight--none\@md-min {
    margin-right: 0;
  }
}

@media screen and (width >= 1200px) {
  .marginRight--none\@lg {
    margin-right: 0;
  }
}
