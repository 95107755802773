@import '../values/spacing.css';
@import '../values/colours.css';
@import '../values/fontSizes.css';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--spacing-small);
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary-1);
  padding: var(--spacing-small);
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  cursor: pointer;
}

.icon {
  line-height: 1;
  font-size: var(--font-size-0);
  font-weight: normal;
  color: var(--color-text-primary);
}

.popoverPrimary {
  color: var(--color-popover-ability-primary);
  font-weight: normal;
  font-size: var(--font-size-2);
  margin-bottom: var(--spacing);
}

.popoverSecondary {
  font-weight: normal;
  font-size: var(--font-size-1);
}
