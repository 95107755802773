@import '../values/widths.css';

.loadingContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.wrapper {
  height: 100%;
  transition: transform 500ms ease;
}
.wrapperSidebar {
  composes: wrapper;
  transform: translateX(--width-sidebar);
}
