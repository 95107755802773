.hidden {
  display: none !important;
}

@media screen and (width < 480px) {
  .hidden\@mobile {
    display: none !important;
  }
}

@media screen and (width < 768px) {
  .hidden\@xs {
    display: none !important;
  }
}

@media screen and (768px <= width < 992px) {
  .hidden\@sm {
    display: none !important;
  }
}

@media screen and (width >= 768px) {
  .hidden\@sm-min {
    display: none !important;
  }
}

@media screen and (992px <= width < 1200px) {
  .hidden\@md {
    display: none !important;
  }
}

@media screen and (width >= 992px) {
  .hidden\@md-min {
    display: none !important;
  }
}

@media screen and (width >= 1200px) {
  .hidden\@lg {
    display: none !important;
  }
}
