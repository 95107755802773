@import './values/colours.css';

html,
button,
input,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}

html {
  font-size: 10px;
  height: 100%;
}

body {
  overflow-x: auto;
  height: 100%;
  background-color: var(--color-base-0);
  margin: 0;
}

#root {
  min-height: 100%;
  width: 100%;
  height: 100%;
}
