@import '../values/colours.css';

.borderBottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--color-border);
}

@media screen and (width < 480px) {
  .borderBottom\@mobile {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--color-border);
  }
}

@media screen and (width >= 768px) {
  .borderBottom\@sm-min {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--color-border);
  }
}

.borderBottom--none {
  border-bottom-width: 0;
}

@media screen and (width < 480px) {
  .borderBottom--none\@mobile {
    border-bottom-width: 0;
  }
}

@media screen and (width < 768px) {
  .borderBottom--none\@xs {
    border-bottom-width: 0;
  }
}

@media screen and (768px <= width < 992px) {
  .borderBottom--none\@sm {
    border-bottom-width: 0;
  }
}

@media screen and (width >= 768px) {
  .borderBottom--none\@sm-min {
    border-bottom-width: 0;
  }
}

@media screen and (992px <= width < 1200px) {
  .borderBottom--none\@md {
    border-bottom-width: 0;
  }
}

@media screen and (width >= 992px) {
  .borderBottom--none\@md-min {
    border-bottom-width: 0;
  }
}

@media screen and (width >= 1200px) {
  .borderBottom--none\@lg {
    border-bottom-width: 0;
  }
}
