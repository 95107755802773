@import '../values/spacing.css';
@import '../values/colours.css';
@import '../values/fontSizes.css';

.heading {
  color: var(--color-text-secondary);
  font-weight: bold;
  margin-top: 0;
}
.heading1 {
  composes: heading;
  font-size: var(--font-size-4);
  margin-bottom: var(--spacing-medium);
}
.heading1Light {
  composes: heading1;
  font-weight: 500;
}
.heading2 {
  composes: heading;
  font-size: var(--font-size-3);
  margin-bottom: var(--spacing);
}
.heading2Light {
  composes: heading2;
  font-weight: 500;
}
.headingCareer {
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  font-size: var(--font-size-5);
  color: var(--color-text-primary);
}
@media screen and (width < 480px) {
  .headingCareer {
    font-size: var(--font-size-4);
  }
}
.headingCareerSmall {
  composes: headingCareer;
  font-size: var(--font-size-4);
}
