@import '../values/spacing.css';
@import '../values/colours.css';
@import '../values/fontSizes.css';
@import '../values/heights.css';

.select {
  display: flex;
  height: 100%;
  align-items: center;
}
.selectLabel {
  color: var(--color-text-secondary);
  font-size: var(--font-size-3);
  margin-right: var(--spacing);
}
@media screen and (width < 480px) {
  .selectLabel {
    font-size: var(--font-size-2);
  }
}
.selectElement {
  height: var(--height-select);
  font-size: var(--font-size-1);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--spacing-small);
  padding-right: var(--spacing-small);
  border: 1px solid var(--color-base-0);
  border-radius: 0;
}
